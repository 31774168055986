/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * LoginForm
 *
 * This component relies on a LoginProvider-like component to supply data and
 * the submit methods via props.
 *
 * Usage:
 *
 * <LoginProvider disableMeta>
 *   <LoginForm formLabels={{ labelUserName: 'Email' }} />
 * </LoginProvider>
 *
 * disableMeta on the LoginProvider, and formLabels on this component are optional props
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import { withTheme } from '@smooth-ui/core-sc';

// Component features
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { required } from '_platform/src/utils/formValidation'; // _platform
import Button from '_platform/src/components/Button/Button'; // _platform
import FormSelect from '_platform/src/components/FormSelect/FormSelect'; // _platform
import FormInput from '_platform/src/components/FormInput/FormInput'; // _platform
import StatusMessage from '_platform/src/components/StatusMessage/StatusMessage'; // _platform
import toast from '_platform/src/utils/toast';

// Default form labels
// These will be _shallow merged_ with props.formLabels (if provided)
// Usage (from parent component):
// <Login formLabels={{ labelUserName: 'Email' }} />
const defaultFormLabels = {
  labelUserName: 'Username',
  labelPassword: 'Password',
};

const webStores = [
  {
    value: 'are',
    label: 'Rexel',
    forgotUsernameUrl: 'https://www.rexel.com.au/are/login/forgotten-username',
    forgotPasswordUrl: 'https://www.rexel.com.au/are/login/pw/request',
    registrationUrl: 'https://www.rexel.com.au/are/register',
  },
  {
    value: 'ajt',
    label: 'John R. Turk',
    forgotUsernameUrl: 'https://www.jrt.com.au/ajt/login/forgotten-username',
    forgotPasswordUrl: 'https://www.jrt.com.au/ajt/login/pw/request',
    registrationUrl: 'https://www.jrt.com.au/ajt/register',
  },
  {
    value: 'aie',
    label: 'Ideal Electrical',
    forgotUsernameUrl:
      'https://www.idealelectrical.com/aie/login/forgotten-username',
    forgotPasswordUrl: 'https://www.idealelectrical.com/aie/login/pw/request',
    registrationUrl: 'https://www.idealelectrical.com/aie/register',
  },
  {
    value: 'als',
    label: 'Lear & Smith',
    forgotUsernameUrl:
      'https://www.learsmith.com.au/als/login/forgotten-username',
    forgotPasswordUrl: 'https://www.learsmith.com.au/als/login/pw/request',
    registrationUrl: 'https://www.learsmith.com.au/als/register',
  },
];

const registrationUrl = values => {
  const selectedStore =
    values &&
    values.webstore &&
    webStores.find(store => store.value === values.webstore);
  return selectedStore ? { href: selectedStore.registrationUrl } : {};
};

const notifyWebstore = () => {
  toast('error', 'Please select a Webstore', {
    autoClose: 2000,
    closeOnClick: true,
    position: 'bottom-center',
  });
};

const usernameUrl = values => {
  const selectedStore =
    values &&
    values.webstore &&
    webStores.find(store => store.value === values.webstore);
  return selectedStore ? { href: selectedStore.forgotUsernameUrl } : {};
};

const passwordUrl = values => {
  const selectedStore =
    values &&
    values.webstore &&
    webStores.find(store => store.value === values.webstore);
  return selectedStore ? { href: selectedStore.forgotPasswordUrl } : {};
};

const handleEnter = () => {
  const submitButton = document.getElementById('submitButton');

  if (submitButton) {
    submitButton.focus();
  }
};

const focusOnError = createDecorator();

const LoginForm = props => {
  const { initialValues, onSubmitForm, status } = props;
  const labels = { ...defaultFormLabels, ...props.formLabels }; // Merge props with default values
  const { labelUserName, labelPassword } = labels;
  // We could avoid merging into the labels const, then destructuring it, however leaving as 2 steps for legibility

  return (
    <div className="login-form">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        decorators={[focusOnError]}
        subscription={{
          // Reduce the number of form re-renders
          // https://github.com/final-form/final-form#formsubscription--string-boolean-
          submitting: true,
          values: true,
        }}
        validate={values => {
          const errors = {};
          const requiredFields = ['webstore', 'username', 'password'];

          requiredFields.map(field =>
            !values[field] ? (errors[field] = 'Required') : false
          );

          return errors;
        }}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} method="POST">
            <Field
              name="webstore"
              component={FormSelect}
              required
              //defaultValue="are"
              validate={required}
              label="Please select a Webstore"
              placeholder="Select webstore..."
              disabled={submitting}
              options={webStores}
              onKeyPress={e => {
                if (e.key === 'Enter') handleEnter();
              }}
            />
            <div className="link-button-container">
              <a
                className="registration-button"
                target="_blank"
                rel="noopener noreferrer"
                {...registrationUrl(values)}
                onClick={() => {
                  values && values.webstore
                    ? window.open(
                        webStores.find(store => store.value === values.webstore)
                          .registrationUrl,
                        '_blank'
                      )
                    : notifyWebstore();
                }}
              >
                No Webstore account? Register here
              </a>
            </div>
            <Field
              name="username"
              component={FormInput}
              type="text"
              label={labelUserName}
              validate={required}
              placeholder={labelUserName}
              disabled={submitting}
              onKeyPress={e => {
                if (e.key === 'Enter') handleEnter();
              }}
            />
            <div className="link-button-container">
              <a
                className="forgot-username-button"
                target="_blank"
                rel="noopener noreferrer"
                {...usernameUrl(values)}
                onClick={() => {
                  values && values.webstore
                    ? window.open(
                        webStores.find(store => store.value === values.webstore)
                          .forgotUsernameUrl,
                        '_blank'
                      )
                    : notifyWebstore();
                }}
              >
                Forgot Username
              </a>
            </div>
            <Field
              name="password"
              component={FormInput}
              type="password"
              label={labelPassword}
              validate={required}
              placeholder={labelPassword}
              disabled={submitting}
              onKeyPress={e => {
                if (e.key === 'Enter') handleEnter();
              }}
            />
            <div className="link-button-container">
              <a
                className="forgot-password-button"
                target="_blank"
                rel="noopener noreferrer"
                {...passwordUrl(values)}
                onClick={() => {
                  values && values.webstore
                    ? window.open(
                        webStores.find(store => store.value === values.webstore)
                          .forgotPasswordUrl,
                        '_blank'
                      )
                    : notifyWebstore();
                }}
              >
                Forgot Password
              </a>
            </div>
            <StatusMessage variant={status.status}>
              {status.statusDetails ||
                (status.status === 'error' && <p>{status.statusDetails}</p>)}
            </StatusMessage>
            <Button type="submit" disabled={submitting} id="submitButton">
              Login
            </Button>
          </form>
        )}
      />
    </div>
  );
};
LoginForm.propTypes = {
  formLabels: PropTypes.shape({
    labelUserName: PropTypes.string,
    labelPassword: PropTypes.string,
    labelRememberMe: PropTypes.string,
  }),
  initialValues: PropTypes.object,
  onSubmitForm: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

LoginForm.defaultProps = {
  formLabels: defaultFormLabels,
  initialValues: {},
  theme: { settingsApp: {} },
};

export default withTheme(LoginForm);
